var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-container',[_c('v-card',[_c('v-card-title',[_vm._v("История звонков")]),_c('DatePeriod',{on:{"change":function($event){return _vm.reloadTable(false)}},model:{value:(_vm.filters.period),callback:function ($$v) {_vm.$set(_vm.filters, "period", $$v)},expression:"filters.period"}}),_c('v-card-actions',[_c('v-row',_vm._l((_vm.callLines),function(lineTitle,line){return _c('v-chip',{key:line,staticClass:"mr-2",attrs:{"color":_vm.callLine===line ? '#9BF0F5' : '#fff',"label":""},on:{"click":function($event){_vm.callLine = line}}},[_vm._v(" "+_vm._s(lineTitle)+" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('CBadge',_vm._g(_vm._b({staticStyle:{"margin-left":"5px","color":"white","background":"#8a93a2"}},'CBadge',attrs,false),on),[_vm._v(" "+_vm._s(_vm.rows.filter(function (row) { return row.callLine === line; }).length)+" ")])]}}],null,true)},[_c('span',[_vm._v("Всего")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('CBadge',_vm._g(_vm._b({staticStyle:{"margin-left":"5px"},attrs:{"color":"success"}},'CBadge',attrs,false),on),[_vm._v(" "+_vm._s(_vm.rows.filter(function (row) { return (row.callLine === line && row.isIncoming); }).length)+" ")])]}}],null,true)},[_c('span',[_vm._v("Принято")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('CBadge',_vm._g(_vm._b({staticStyle:{"margin-left":"5px"},attrs:{"color":"danger"}},'CBadge',attrs,false),on),[_vm._v(" "+_vm._s(_vm.rows.filter(function (row) { return (row.callLine === line && row.isMissed); }).length)+" ")])]}}],null,true)},[_c('span',[_vm._v("Пропущено")])])],1)}),1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"hover":"","items":_vm.rows.filter(function (row){ return row.callLine===_vm.callLine; }),"headers":_vm.headers,"item-key":"id","loading":_vm.loadingTable,"footer-props":{'items-per-page-options':[-1]},"no-data-text":"Список пуст","loading-text":"Загрузка...","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('tr',[_c('td',[_vm._v(_vm._s(index + 1)+" "),(item.record)?_c('v-icon',{attrs:{"color":"blue"},on:{"click":function($event){return _vm.$eventBus.$emit('playAudio', item.record)}}},[_vm._v(" mdi-play-circle-outline ")]):_vm._e()],1),_c('td',[_vm._v(" "+_vm._s(item.datetime_createObj.time)+" "+_vm._s(_vm.$root.dateToRus(item.datetime_createObj.date, 1)))]),_c('td',[(Number(item.datetime_start))?[_vm._v(" "+_vm._s(item.datetime_startObj.time)+", через "+_vm._s(item.datetime_start - item.datetime_create)+" с ")]:_vm._e()],2),_c('td',[_vm._v(" "+_vm._s(item.datetime_endObj.time)+" ")]),_c('td',[(parseInt(item.datetime_end))?[_vm._v(" "+_vm._s(_vm.$root.printTimeDiff(item.datetime_end - (item.datetime_start * 1 || item.datetime_create)))+" ")]:_vm._e()],2),_c('td',[_c('v-chip',{staticStyle:{"color":"white"},attrs:{"small":"","color":item.color}},[_vm._v(" "+_vm._s(item.statusStr)+" ")])],1),_c('td',[(!item.client || item.callDirection!=='incoming')?[_vm._v(_vm._s(item.phone_from))]:_vm._e(),(item.callDirection==='incoming')?[(item.client)?_c('ClientLink',{attrs:{"value":item.client}}):_vm._e(),(item.cleaner)?_c('CleanerLink',{attrs:{"value":item.cleaner}}):_vm._e()]:_vm._e()],2),_c('td',{staticStyle:{"min-width":"170px"}},[(!item.client || item.callDirection!=='outgoing')?[_vm._v(_vm._s(item.phone_to))]:_vm._e(),(item.callDirection==='outgoing')?[(item.client)?_c('ClientLink',{attrs:{"value":item.client}}):_vm._e(),(item.cleaner)?_c('CleanerLink',{attrs:{"value":item.cleaner}}):_vm._e()]:_vm._e()],2),_c('td',{staticStyle:{"max-width":"300px"}},[(item.utm)?[_vm._l((item.utm.utm),function(val,key){return _c('div',[_vm._v(_vm._s(key)+": "),_c('span',{staticStyle:{"color":"#2196f3"}},[_vm._v(_vm._s(val))])])}),(item.utm.$data && item.utm.$data.first_page)?_c('div',[_vm._v("Страница входа: "+_vm._s(item.utm.$data.first_page.url)+" ")]):_vm._e()]:_vm._e()],2)])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }